<template>
  <div class="d-flex justify-content-center">
    <b-col
      cols="12"
      md="4"
    >
      <b-card>
        <div class="contact-box center-version">
          <div class="d-flex align-items-center flex-column">

            <div>
              <b-avatar
                size="100"
                :src="userMsgsData.image"
                :text="avatarText(userMsgsData.user_name)"
                class="mb-2"
              />
            </div>
            <h3 class="mb-2">
              <strong>{{ userMsgsData.user_name }} </strong>
            </h3>

          </div>
          <div class="product_gifts-info">
            <ul>

              <li>
                <feather-icon
                  icon="MailIcon"
                /> Email: {{ userMsgsData.user_email }}
              </li>
              <li>
                <feather-icon
                  icon="PhoneIcon"
                /> Mobile : {{ userMsgsData.user_mobile }}
              </li>
              <li>
                <feather-icon
                  icon="CalendarIcon"
                /> Date : {{ userMsgsData.created_at }}
              </li>
              <li>
                <feather-icon
                  icon="ColumnsIcon"
                /> Message Category: {{ userMsgsData.category_name_ar }} -  {{ userMsgsData.category_name_en }}
              </li>
              <li>
                <feather-icon
                  icon="ColumnsIcon"
                /> Message Details : {{ userMsgsData.message }}
              </li>

            </ul>
          </div>
        </div>
      </b-card>

    </b-col>
  </div>

</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
  },

  setup() {
    const { router } = useRouter()
    const userMsgsData = ref({})
    const columns = [
      { key: 'user_name', label: 'User Name' },
      { key: 'image', label: 'Image' },

    ]

    return {
      columns,
      router,
      userMsgsData,
      avatarText,

    }
  },
  mounted() {
    this.viewuserMsgsData(this.$route.params.id)
  },
  methods: {

    async viewuserMsgsData(id) {
      if (id) {
        await axios.get(`admin/user_messages/${id}`).then(res => {
          if (res.status === 200) {
            this.userMsgsData = res.data?.data.user_messages
          }
        })
      }
    },

  },
}
</script>

  <style scoped lang="scss">
  .padd span {
    padding: 2px;
  }
  .change-status {
    cursor: pointer;
  }
  .product_gifts-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
  </style>
